<template>
  <div class="login-head">
    <span @click="$router.push('/')">
      <img src="@/assets/img/logo.png" alt="logo" />
    </span>
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    home() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.login-head {
  display: flex;
  align-items: center;
  height: 75px;
  padding: 0 24px;
  background: #fff;
  border-bottom: 1px solid #e2e8f0;

  > span {
    cursor: pointer;
    height: 38px;
    display: flex;
    align-items: center;

    > img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  > p {
    margin-left: 24px;
    padding-left: 24px;
    font-size: 18px;
    line-height: 24px;
    border-left: 1px solid #f5f5f5;
  }
}
</style>
